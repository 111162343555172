<template>
  <TTView>
    <VRow>
      <VCol>
        <QuestionsTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import QuestionsTableContainer from '../../../components/multi-level-test/questions/QuestionsTableContainer.vue';

export default {
  name: 'LevelsQuestions',

  components: {
    QuestionsTableContainer,
  },
};
</script>
