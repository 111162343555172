<template>
  <QuestionsTable
    :questions="questions"
    :levels="levels"
    :loading="loading"
  >
    <template #topActions>
      <VBtn
        color="primary"
        :to="{
          name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CREATE
        }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новый вопрос
      </VBtn>

      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить вопрос
          </VCardTitle>

          <VCardText>
            Вопрос <span class="red--text">{{ question.title }}</span> будет удален! <br>
            Действительно хотите удалить Вопрос?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <VBtn
              text
              @click="handleCancel"
            >
              Нет
            </VBtn>

            <VBtn
              color="red darken-1"
              text
              @click="handleDelete"
            >
              Да
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_VIEW,
              params : {
                testId : testId,
                levelId : levelId,
                questionId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_INDEX,
              params : {
                testId : testId,
                levelId : levelId,
                questionId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-list
            </VIcon>
          </VBtn>
        </template>

        <span>Ответы</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="orange"
            class="mx-1"
            :to="{
              name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_EDIT,
              params : {
                testId : testId,
                levelId : levelId,
                questionId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click.stop="deleteQuestion(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>
    </template>
  </QuestionsTable>
</template>

<script>
import QuestionsTable from './QuestionsTable.vue';

export default {
  name: 'QuestionsTableContainer',

  components: {
    QuestionsTable,
  },

  inject: ['Names'],

  data() {
    return {
      questions: [],
      levels: [],
      loading: false,
      question: {},
      confirmDialog: false,
    };
  },

  computed: {
    testId() {
      return this.$route.params.testId;
    },

    levelId() {
      return this.$route.params.levelId;
    },

    topicId() {
      return this.$route.params.topicId;
    },
  },
  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { levelId } = this;
        const data = { levelId };

        const { questions } = await this.$di.api.MultiLevelTest.QuestionsByLevel({ data });
        const { levels } = await this.$di.api.MultiLevelTest.LevelsIndex();

        this.questions = questions;
        this.levels = levels;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleDelete() {
      try {
        this.loading = true;

        const { id } = this.question;
        const data = { id };

        await this.$di.api.MultiLevelTest.QuestionsDelete({ data });

        this.resetQuestionEdit();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
    },

    resetQuestionEdit() {
      this.confirmDialog = false;
      this.question = {};
    },

    handleCancel() {
      this.resetQuestionEdit();
    },

    deleteQuestion(item) {
      this.confirmDialog = true;
      this.question = { ...item };
    },
  },
};
</script>
